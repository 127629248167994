<template>

<header>
      <router-link to="/" class="logo"><img src="@/assets/img/g6.png" width="90" ></router-link>
      <a href="#" class="logo"></a>
      <div class="menu" :class="{'active':isActive}">
        <div class="btn" >
          <i class="close-btn" ><img src="@/assets/img/cross.png"></i>
        </div>
        <router-link to="/" @click="pushforth('home')">Home</router-link>
        <router-link to="/aboutUs" @click="pushforth('aboutUs')">Über uns</router-link>
        <router-link to="/leistungen" @click="pushforth('leistungen')">Leistungen</router-link>
        <router-link to="/download" @click="pushforth('download')">Download</router-link>

      </div>
      <div class="btn">
        <i class="menu-btn" @click="isActive = true"><img src="@/assets/img/hamburger.png"></i>
      </div>
    </header>

</template>



<script>
//import store from '@/assets/js/store.js';
import { computed,watch, ref } from 'vue'
import { useRouter, useRoute} from 'vue-router'
export default {
  name: 'navbar',
  props: {

  },
  setup(){
    const isActive = ref(false)
    const router = useRouter()
    const route = useRoute()



   const seitenName = computed(() => {
      return route.name
    })

    watch(seitenName, (seitenName) => {
      if(seitenName !=="home") {
      isActive.value = false
      }
    })

    function pushforth (to) {


      router.push({name:to});
      console.log("Ich funktioniere");
      console.log(isActive.value);
    }

      return { pushforth,isActive}
    }

  }


</script>
<style>
.logo{

animation: fadein 4s;
}

.menu{

animation: fadein 6s;
}


@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
</style>
