<template>

<div style="background:url(/back.jpg); opacity:0.9; height:100vh;">
<div class="logo">
<div style="position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);">
<center>
<div class="fade" style="color: hsla(216, 100%, 58%, 1);">
Fremdüberwachung und Zertifizierung Süd UG (haftungsbeschränkt)
</div>
</center>
</div>
<div style="position: absolute; right: 0px; bottom: 0px"> <img src="@/assets/img/logo-CSC-small.png"></div>
</div>

</div>
<br><br><br><br>
<div class="cont">
<div class="vertical">
  <section class="section-three">
  <h3>Herzlich Willkommen auf den Webseiten der Fremdüberwachung und Zertifizierung Süd UG (haftungsbeschränkt).</h3>
  </section>
</div>
</div>



</template>

<script>
export default {
  name: 'Home',

  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 }
}
</script>
<style scoped>
@keyframes fadeIn{
from{
	opacity: 0;
}
to {
	opacity: 1;
}
}
.fade {
	animation: fadeIn 2.5s ease-in alternate;
  font-size: 32pt;
 color: hsla(216, 100%, 58%, .9);
}


</style>
